:root {
  --bg-primary: #0a0a0a;
  --bg-secondary: #111;
  --bg-tertiary: #1a1a1a;
  --text-primary: #7fff00;
  --text-secondary: #00ffff;
  --text-tertiary: #ff00ff;
  --border-color: #3a3;
  --light-blue: #e6f3ff;
  --eth-blue: #00c3ff;
}

/* Cross-browser scrollbar styling */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--text-primary) var(--bg-secondary);
}

/* WebKit scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: var(--bg-secondary);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: var(--text-primary);
  border-radius: 4px;
  border: 2px solid var(--bg-secondary);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--text-secondary);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Courier New', monospace;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.App {
  height: 100vh;
}

.blotter-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.blotter-content {
  flex: 1;
  border: 2px solid var(--text-primary);
  box-shadow: 0 0 10px var(--text-primary), 0 0 20px var(--text-secondary), 0 0 30px var(--text-tertiary);
  padding: 20px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.blotter-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--light-blue);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
  text-shadow: none;
  font-weight: bold;
  letter-spacing: 2px;
}

.blotter-title .main-title {
  font-size: 2.5rem;
  margin: 0;
}

.blotter-title .sub-title {
  font-size: 1rem;
  color: var(--eth-blue);
  margin-top: 5px;
  letter-spacing: 4px;
}

.search-container {
  background-color: var(--bg-secondary);
  border: 1px solid var(--text-primary);
  padding: 10px;
  margin-bottom: 20px;
}

.search-prompt {
  color: var(--text-secondary);
  margin: 0;
  display: flex;
  align-items: center;
}

.search-input {
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-family: inherit;
  font-size: inherit;
  margin-left: 5px;
  outline: none;
  flex-grow: 1;
}

.cursor {
  color: #ffffff;
}

.table-container {
  background-color: var(--bg-secondary);
  border: 1px solid var(--text-primary);
  padding: 10px;
  margin-bottom: 20px;
  overflow-x: auto;
  max-height: calc(100vh - 350px);
  overflow-y: auto;
}

.event-table {
  border-collapse: collapse;
  width: 100%;
}

.event-table th,
.event-table td {
  padding: 5px;
  text-align: left;
  border-bottom: 1px solid #333;
}

.event-table th {
  color: var(--text-secondary);
  border-bottom: 1px solid var(--text-primary);
}

.selected-row {
  background-color: var(--bg-tertiary);
  transform: translateZ(5px);
  box-shadow: 0 0 10px rgba(127, 255, 0, 0.5);
}

.event-type {
  white-space: nowrap;
}

.event-type.hack { color: #ff0000; }
.event-type.enforcement { color: #0000ff; }
.event-type.exploit { color: #ffff00; }
.event-type.rug { color: #800080; }

.event-type-content {
  display: flex;
  align-items: center;
  gap: 5px;
}

.amount {
  text-align: right;
}

.source-link {
  color: var(--text-secondary);
  text-decoration: none;
}

.blotter-footer {
  background-color: var(--bg-secondary);
  border: 1px solid var(--text-primary);
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-button {
  background-color: transparent;
  border: 1px solid;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.submit-button {
  border-color: var(--text-primary);
  color: var(--text-primary);
}

.contact-button {
  border-color: var(--text-secondary);
  color: var(--text-secondary);
}

/* Update event type colors in icons */
.event-type-content svg.text-red-500 { color: #ff0000; }  /* hack */
.event-type-content svg.text-yellow-500 { color: #ffff00; }  /* exploit */
.event-type-content svg.text-blue-500 { color: #0000ff; }  /* enforcement */
.event-type-content svg.text-purple-500 { color: #800080; }  /* rug */

@media (max-width: 768px) {
  .blotter-container {
    padding: 10px;
  }

  .blotter-content {
    padding: 10px;
  }

  .table-container {
    max-height: calc(100vh - 300px);
  }

  .event-table {
    font-size: 0.9rem;
  }
}

/* Protocol Page Styles */
.protocol-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.protocol-content {
  flex: 1;
  border: 2px solid var(--text-primary);
  box-shadow: 0 0 10px var(--text-primary), 0 0 20px var(--text-secondary), 0 0 30px var(--text-tertiary);
  padding: 20px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.protocol-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.protocol-name {
  color: var(--light-blue);
  font-size: 2rem;
  margin: 0;
}

.back-link {
  color: var(--eth-blue);
  text-decoration: none;
  font-size: 1.1rem;
}

.back-link:hover {
  text-decoration: underline;
}

.info-section {
  margin-bottom: 30px;
  padding: 20px;
  background-color: var(--bg-secondary);
  border: 1px solid var(--text-primary);
}

.info-section h3 {
  color: var(--light-blue);
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.2rem;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.info-item .label {
  color: var(--text-secondary);
}

.info-item .value {
  color: var(--light-blue);
}

.links-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
}

.link-item {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--eth-blue);
  text-decoration: none;
  padding: 10px;
  border: 1px solid var(--eth-blue);
  border-radius: 4px;
  transition: all 0.3s ease;
}

.link-item:hover {
  background-color: var(--eth-blue);
  color: var(--bg-primary);
}

.audits-list, .exploits-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.audit-item, .exploit-item {
  display: flex;
  gap: 15px;
  padding: 15px;
  background-color: var(--bg-tertiary);
  border: 1px solid var(--text-primary);
}

.audit-item .icon, .exploit-item .icon {
  color: var(--eth-blue);
}

.audit-details, .exploit-details {
  flex: 1;
}

.audit-firm, .exploit-type {
  color: var(--light-blue);
  font-weight: bold;
}

.audit-date, .exploit-date {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.audit-findings, .exploit-description {
  color: var(--text-primary);
  margin-top: 5px;
}

.exploit-amount {
  color: var(--eth-blue);
  font-weight: bold;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .protocol-container {
    padding: 10px;
  }

  .protocol-content {
    padding: 10px;
  }

  .protocol-name {
    font-size: 1.5rem;
  }

  .info-section {
    padding: 15px;
  }

  .audit-item, .exploit-item {
    flex-direction: column;
    padding: 10px;
  }
}

.protocol-link {
  color: var(--eth-blue);
  text-decoration: none;
  transition: color 0.3s ease;
}

.protocol-link:hover {
  color: var(--light-blue);
  text-decoration: underline;
}